import axios from "axios";

export default {
    namespaced: true,
    state: {
        loaded: false,
        products: [
           /* {
            "name": "Moss Beach Life Book",
            "type": "Book",
            "description": "110 Page Photo Book",
            "imageUrl": "products/books/mossbeachlife/product.jpg",
            "details": {
                "description": "This is a sample book",
                "productImage": "products/books/mossbeachlife/product.jpg",
                "images": [
                    {
                        "url": "products/books/mossbeachlife/image1.png",
                        "description": "Cover"
                    },
                    {
                        "url": "products/books/mossbeachlife/spread1.png",
                        "description": "Full Page Spread1"
                    },
                    {
                        "url": "products/books/mossbeachlife/spread2.png",
                        "description": "Full Page Spread2"
                    },
                    {
                        "url": "products/books/mossbeachlife/spread3.png",
                        "description": "Full Page Spread3"
                    }
                ]
            },
            "items": [
                {
                    "productId": "prod_Qxn8Bv9qleVWpq",
                    "priceId": "price_1Q5rYUFuBPTXHmxdEO2ANe8c",
                    "name": "MossBeachLife Book, 12x12 Hardcover",
                    "description": "110 Page Photo book, Hard cover, Layflat pages",
                    "imageUrl": "https://files.stripe.com/links/MDB8YWNjdF8xRU9JSElGdUJQVFhIbXhkfGZsX3Rlc3RfMjQ4TzdKMG9mT09Vb3hkSzh0dkJPdVU500vJFN2wdb",
                    "unitPrice": 12000,
                    "unitWeight": "96",
                    "features": [
                        "Hardcover",
                        "Layflat"
                    ],
                    "specialMessage": "Limited Quantities",
                    "size": "12x12",
                    "metadata": {}
                },
                {
                    "productId": "prod_QZYBp5CsL4Wn5q",
                    "priceId": "price_1PiP5JFuBPTXHmxdbKegyPSF",
                    "name": "MossBeachLife Book, 10x10 Hardcover",
                    "description": "110 Page Photo book, Hard cover.",
                    "imageUrl": "https://files.stripe.com/links/MDB8YWNjdF8xRU9JSElGdUJQVFhIbXhkfGZsX3Rlc3RfQ0RlOHZZOHl1c0RsdW1GUU5wUVdjdnZJ00BSBcrhkF",
                    "unitPrice": 9000,
                    "unitWeight": "32",
                    "features": [
                        "Hardcover"
                    ],
                    "specialMessage": "Limited Quantites",
                    "size": "10x10",
                    "metadata": {}
                }
            ]
        },
            {
                "name": "5x7 Card Set, Wildlife",
                "type": "Card",
                "description": "5 card set",
                "imageUrl": "products/cards/wildlife_cardset/product.png",
                "details": {
                    "description": "5 card Set, folding cards",
                    "productImage": "products/cards/wildlife_cardset/product.png",
                    "images": [
                        {
                            "url": "products/cards/wildlife_cardset/CARDSET_WILDLIFE_1.png",
                            "description": "Card1 Description"
                        },
                        {
                            "url": "products/cards/wildlife_cardset/CARDSET_WILDLIFE_2.png",
                            "description": "Card2 Description"
                        },
                        {
                            "url": "products/cards/wildlife_cardset/CARDSET_WILDLIFE_3.png",
                            "description": "Card3 Description"
                        },
                        {
                            "url": "products/cards/wildlife_cardset/CARDSET_WILDLIFE_4.png",
                            "description": "Card4 Description"
                        }
                    ]
                },
                "items": [
                    {
                        "productId": "prod_QxQPCaBJzRNWiS",
                        "priceId": "price_1Q5VYUFuBPTXHmxdgKl25LXe",
                        "name": "5x7 Card Set, Wildlife",
                        "description": "5 card set",
                        "imageUrl": "https://files.stripe.com/links/MDB8YWNjdF8xRU9JSElGdUJQVFhIbXhkfGZsX3Rlc3RfMzZMcWZUZHhrWkM4ZlpSN1ZlMTRTcEF000HyBvyjl5",
                        "unitPrice": 1500,
                        "unitWeight": "6",
                        "features": [
                            "Folded"
                        ],
                        "specialMessage": "Limited Quantites",
                        "size": "5x7",
                        "metadata": {}
                    }
                ]
            }*/
            ]
    },
    getters: {
        products: (state) => state.products,
        loaded: (state) => state.loaded
    },
    mutations: {
        products(state, products) {
            state.products = products
        },
        loaded(state, loaded) {
            state.loaded = loaded
        }
    },
    actions: {
        async loadProducts(context) {
            if (!context.getters["loaded"]) {
                const catalogUrl = process.env.VUE_APP_API_URL + "/catalog";
                const response = (await axios.get(catalogUrl, {
                    headers: {
                        'x-api-key': process.env.VUE_APP_API_KEY
                    }
                }));

                const products = response.data;
                await context.commit("products", products);
                await context.commit("loaded", true);
            }
        }
    },
    modules: {
    }
}
